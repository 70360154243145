.circuit-timer {
    text-align: center;
  }
  
  .exercise-name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .timer-display {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }
  
  .progress-ring__circle {
    stroke: #ddd;
  }

  .progress-ring__progress.rest {
    stroke: #A10404;
    transform-origin: center;
    transform: rotate(-90deg);
    transition: stroke-dasharray 0.3s;
  }
  
  .progress-ring__progress.active {
    stroke: #03A142;
    transform-origin: center;
    transform: rotate(-90deg);
    transition: stroke-dasharray 0.3s;
  }
  
  .timer-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }
  
  .timer-controls {
    margin-top: 20px;
  }
  
  .timer-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin: 0 10px;
  }
  
  .stop-button {
    color: #A10404;
  }
  
  .play-button {
    color: #03A142;
  }
  
  .rounds {
    margin-top: 20px;
    font-size: 20px;
  }
  