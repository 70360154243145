.exercise-information {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .exercise-information h2 {
    font-size: 24px;
    color: #333;
  }
  
  .exercise-details {
    margin-top: 20px;
  }
  
  .exercise-details p {
    margin-bottom: 10px;
  }
  
  .exercise-details strong {
    font-weight: bold;
  }
  
  .exercise-difficulty {
    margin-left: 10px;
    color: #777;
  }
  