.records-container {
    padding: 2px;
    border: 1px solid rgb(214, 0, 0);
  }
  
  .record-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1px 0;
  }
  
  .record-row.even {
    background-color: rgb(255,242,226)
  }
  
  .record-row.odd {
    background-color: #ffffff;
  }
  
  .record-header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .record-header h4 {
    margin: 0;
    flex: 1;
  }
  
  .record-buttons {
    margin-left: 20px;
  }
  
  .record-data {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .record-field {
    flex: 1;
    margin-right: 0px;
  }
  
  .record-field label {
    display: block;
    font-weight: bold;
  }
  
  hr {
    margin: 0px 0;
    border: none;
    border-top: 1px solid #ccc;
  }

  .records-description {
    margin-bottom: 20px;
  }
  
  .records-description p {
    margin-bottom: 10px;
  }
  
  .records-description ul {
    list-style-type: none;
    padding: 0;
  }
  
  .records-description ul li {
    margin-bottom: 5px;
  }
  
  .records-description ul li strong {
    font-weight: bold;
  }
  
  .records-description ul li:before {
    content: "•";
    margin-right: 5px;
  }
  
  