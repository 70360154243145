.delete-button {
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
    background-color:rgb(205, 205, 205);
    border: none;
    cursor: pointer;
  }
  #logicEditorForm{
    max-width: 50%;
    margin: auto;
  }