
.login-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
}
input, select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input#name {
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: #04A1A1; /* complimentary to #a10404 */
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

div#login {
  border-radius: 5px;
  color:#000;
  padding: 20px;
}

/*Logo*/
#logo {
  max-width: 100%;
}

.logo-image {
  width: 100%;
  height: auto;
  display: block;
}


/* Button Styling*/
div#sign-up-or-login {
  text-align: center;
}

div#sign-up-or-login button {
  display: inline-block;
  border-radius: 20px;
  background-color: #a10404;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

div#sign-up-or-login button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

div#sign-up-or-login button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

div#sign-up-or-login button:hover span {
  padding-right: 25px;
}

div#sign-up-or-login button:hover span:after {
  opacity: 1;
  right: 0;
}

/* Show or hide pw on login form*/
.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: 25px;
  position: relative;
  z-index: 2;
}