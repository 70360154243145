.workout-grid-container {
  cursor: grab;
  overflow: hidden;
  margin: 0 10%;
  max-height: 300px;
}

.workout-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

.workout-grid > * {
  flex-basis: calc(33.33% - 20px);
  width: calc(33.33% - 20px);
  margin: 10px;
}

.workoutGridItem {
  border: 2px solid  #A10404;
  height:100px;
  padding: 10px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  color: rgb(0, 0, 0);
}

.current-workout {
  background-color:  #A10404;
  border: 2px solid  #000;
  border-radius: 20px;
  padding: 10px;
  color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  height:100px;
}

.workout-name {
  font-size: 14px;
  margin-bottom: 5px;
}

.exercise-list {
  list-style: decimal;
  padding: 10px;
}

.exercise-list li {
  font-size: 12px;
  margin-bottom: 3px;
}

.workoutGridItem,
.current-workout {
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.current-workout .ellipsis-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width:80px;
}

.workoutGridItem:hover,
.workoutGridItem:active,
.current-workout:hover,
.current-workout:active {
  transform: scale(1.1);
}