#exercise-in-editing{
  background-color: #f5f5f5;
}
  input[type=text], select {
    width: 100%;
    min-width: 140px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid rgb(124, 9, 9);
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }

.exercise-program-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: auto;
  height: auto;
  padding: 2px 10px;
  position:center;

  text-align: center
}

/* On mouse-over, add a deeper shadow */
.exercise-program-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color: rgba(214, 0, 0, 0.692);
}

/* Add some padding inside the card container */
.program-editing-container {
  padding: 2px 16px;
  margin-bottom: 10px;
}

div#program-creation-container{
  text-align: center;
  }

div#container{
  text-align: center;
  }

.btn {
  display: inline-block;
  padding:0px;
}