.list-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .list-item-column {
    flex-basis: 33.33%;
  }

/* Override the padding for elements inside the .row class */
.row .col.history {
  padding: 5px;
}
  
  .view-button {
    padding: 6px 12px;
    margin-right: 10px;
    background-color:  #A10404;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .expanded-content {
    margin-top: 10px;
    padding: 10px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  