.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem .5rem .5rem;
  border: 2px solid rgb(161, 4, 4);
  border-radius: 10px;
  position: relative;
  width: 200px;
  height: 250px;
  background-position: center;
  margin: 5px auto;
  color: #fff;
  overflow:hidden;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.554);
  z-index: 1;
}

.card h4 {
  margin-bottom: 5px;
  margin-top: .5rem;
  z-index: 2;
}

.card .program-info{
margin-top: 5px;
}
.card span {
  display: block;
  max-width: 180px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: .75rem !important;
  font-family: 'Times New Roman', Times, serif;
  position: relative;
  z-index: 2;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  z-index: 2;
}

.card .btnn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(161, 4, 4);
  color: #fff;
  border: none;
  outline: none;
  font-size: 1rem;
  border-radius: 10px;
  padding: 11px 1rem;
  width: 5rem;
  z-index: 2;
}
