#exerciseEditor {
  padding: 10px;
}

#resp-table-exercise {
  width: 100%;
  margin: auto;
  padding: 0;
  display: table;
  text-align: center;
  border: 1px solid;
}

div#resp-table-body-exercise > div:nth-of-type(odd) {
  background: rgb(233, 233, 233);
}

#resp-table-header-exercise {
  display: table-header-group;
  font-weight: bold;
  font-size: 1rem;
}

.table-header-cell-exercise {
  margin: 5px !important;
  border: 1px solid;
  font-size: 1rem;
}

#resp-table-body-exercise {
  display: table-row-group;
  font-size: 1rem;
}

.resp-table-row-exercise {
  display: table-row;
  text-align: center;
  font-size: 1rem;
}

.table-body-cell-exercise {
  display: table-cell;
  padding: 10px;
  text-align: left;
  border: 1px solid;
}

#resp-table-exercise span {
  display: block;
  max-width: 100px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem !important;
}

.table-body-cell-exercise#edit-btn {
  border: none !important;
}
