.dashboard-container {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .current-program,
  .current-program > *,
  .current-program + hr,
  #workouts-in-current-program,
  #workouts-in-current-program > *,
  #workouts-in-current-program + hr,
  #dashboard-stats,
  #dashboard-stats > * {
    margin: 10px auto;
    max-width: 400px;
  }
  

div#dashboard-heading {
    float: right;
}

#createOrBuy-btns{
  display:flex;
  justify-content: space-between;
}

/* Div Table styling for workout*/

#resp-table {
    width: 100%;
    margin: auto;
    display: table;
    text-align: center;
}

div#resp-table-body>div:nth-of-type(odd) {
    background: rgb(233, 233, 233);
}

#resp-table-header {
    display: table-header-group;
    font-weight: bold;
}

.table-header-cell-left {
    display: table-cell;
    padding: 10px;
    text-align: left;
}

.table-header-cell-right {
    display: table-cell;
    padding: 10px;
    text-align: right;
}

#resp-table-body {
    display: table-row-group;
}

.resp-table-row {
    display: table-row;
    text-align: center;
}

.table-body-cell-dashboard-left {
    display: table-cell;
    border: none;
    padding: 10px;
    text-align: left;
}

.table-body-cell-dashboard-right {
    display: table-cell;
    border: none;
    padding: 10px;
    text-align: right;
}

.current-program-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: solid;
    border-color: rgb(161, 4, 4);
    border-width: thick;
    transition: 0.3s;
    width: 6em;
    height: 8em;
    padding: 20px 10px;
    margin: 10px;
    overflow: hidden;
    text-align: center;
}
.current-program{
  align-items: center;
}

.current-workout-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: solid;
    border-color: rgb(161, 4, 4);
    border-width: thick;
    transition: 0.3s;
    width: 6em;
    height: 8em;
    padding: 20px 10px;
    margin: 10px;
    overflow: hidden;
    text-align: center;
}

.program-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 6em;
    height: 8em;
    padding: 20px 10px;
    margin: 10px;
    overflow: hidden;
    text-align: center;
    position: center;
}


/* On mouse-over, add a deeper shadow */
.program-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(214, 0, 0, 0.692);
}

.dashboard-btn {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: 0 1px 3px rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15);
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform, opacity;
    margin: 10px;
    z-index: 0;
  }
  
  .dashboard-btn:hover {
    background: #F6F9FE;
    color: #04A1A1; /* complimentary to #a10404 */
  }
  
  .dashboard-btn:active,
  .dashboard-btn:focus {
    outline: none;
    box-shadow: 0 4px 4px 0 rgba(60, 64, 67, .3), 0 8px 12px 6px rgba(60, 64, 67, .15%);
  }
  
  .dashboard-btn:not(:disabled) {
    box-shadow: 0 1px 3px rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15%);
  }
  
  .dashboard-btn:not(:disabled):hover,
  .dashboard-btn:not(:disabled):focus,
  .dashboard-btn:not(:disabled):active {
    box-shadow: 0 2px 3px rgba(60, 64, 67, .3), 0 6px 10px 4px rgba(60, 64, 67, .15%);
  }
  
  .dashboard-btn:disabled {
    box-shadow: 0 1px 3px rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15%);
  }
  