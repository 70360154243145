.workout-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  align-items: center;
  transition: 0.3s;
  margin: 10px;
  text-align: center;
  border: 2px solid #ccc;
  height: 140px;
  width:140px;
  padding: 5px;
  border-radius: 20px;
  background-color: rgba(161, 4, 4, 0.186);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: rgb(0, 0, 0);
  position:relative;
  overflow: hidden;
}

.exercise-list-container{
  height:50px;
  overflow: hidden;
}

/* On mouse-over, add a deeper shadow */
.workout-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color: #a10404;
  border: 2px solid #ccc;
  color:#fff;
}

.btn-container {
  position: absolute;
bottom: 0;
left:0;
right:0;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

/* Add some padding inside the card container */
.program-editing-container {
  padding: 2px 16px;
  margin-bottom: 10px;
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.program-details {
  padding: 10px 1px;
  margin: 0 auto;
  max-width: 800px;
}

.large-textarea {
  height: 200px;
  resize: vertical;
}


#workout-card-container{
  padding: 10px 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: grab;
  margin: 0 auto;
  max-width: 800px;
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: white;
}

div#program-creation-container{
  text-align: center;
  }

div#container{
  text-align: center;
  }