.inner-carousel{
    display:flex;
    padding:30px;
}

.carousel{
    cursor:grab;
    overflow:hidden;
    margin:0 10%;
}
.item{
    margin:10px !important;
}