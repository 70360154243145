li.item {
    display: flex;
    cursor: move;
    background: #fff;
    align-items: center;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 11px;
    border: 1px solid #ccc;
    justify-content: space-between;
  }
  
  .item .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .item .details .number {
    flex-shrink: 0;
    margin-right: 5px;
  }
  
  .item .details .workout-name {
    flex-grow: 1;
    text-align: center;
  }

  .item .details span {
    font-size: 10px;
  }
  .item .details i {
    color: #474747;
    font-size: 10px;
    cursor: auto;
    margin-left: 5px; 
  }
  .item.dragging {
    opacity: 0.8;
  }
  .item.dragging .details, .item.dragging i {
    opacity: 0;
  }

  /* Set the maximum height to limit the display to 5 items */
  /* Hide the scroll bars */
  /* Enable vertical panning */
  /* Enable vertical scrolling */
  .list-container {
    max-height: 250px;
    overflow: hidden;
    touch-action: pan-y;
    overflow-y: auto;
  }
  
   /* Enable vertical scrolling */
    /* Enable smooth scrolling on iOS */
  .list-group {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Custom scroll style */
.list-group::-webkit-scrollbar {
  width: 0.5rem; 
  background-color: transparent;
}

 /* Set the color of the scrollbar thumb */
.list-group::-webkit-scrollbar-thumb {
  background-color: #888;
}

 /* Set the color of the scrollbar thumb on hover */
.list-group::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: .25em;
  margin: 0 auto;
  max-width: 800px;
  padding: 0;
}

.logic-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: grey;
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.logic-icon.active {
  background-color: green;
}
  
