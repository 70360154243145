.App {
  font-family: sans-serif;
  text-align: center;
}

/* Div Table styling for workout*/

#resp-table {
  width: 100%;
  margin:auto;
  display: table;
  text-align: center;
  padding: 20px;  
  font-size: clamp(12px, 2vw, 20px);
  }

  div#resp-table-body>div:nth-of-type(odd) {
    background: rgb(233, 233, 233);
  }
  
  #resp-table-header{
  display: table-header-group;
  font-weight: bold;
  }
  
  .table-header-cell{
  display: table-cell;
  padding: 1px;
  text-align: center;
  }

  #resp-table-body{
    display: table-row-group;
  }

  .resp-table-row{
      display: table-row;
      text-align: center;
  }

  .table-body-cell{
    display: table-cell;
    border: none;
    padding: 5px;
    width:auto;
    }

input[type="number"] {
  min-width: 20px;
  max-width: 80px;
  padding: 5px;
}

Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} 

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Dashed exercise border */
hr.dashed {
  border-top: 3px dashed #bbb;
}

/* Circuit Timer Controls */
#circuitTimerControls button {
  font-size: 30px;
  padding: 5px;
  background-color: Transparent;
  border: none;
}

#circuitrounds label {
  padding: 10px;
}
